<template>
  <vx-card
    class="overflow-hidden mb-2"
    :title="
      lang.surveyIntegrations.channels.whatsapp.settings.hsm.title[
        languageSelected
      ]
    "
    :subtitle="lang.helpTooltips.surveys.hsm[languageSelected]"
  >
    <div class="vx-row">
      <vs-col :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-radio
          v-for="type in messageTypes"
          :key="type.text"
          v-model="messageTypeR"
          :vs-name="type.text"
          :vs-value="type.text"
          v-on:input="$emit('update:messageType', $event)"
          class="mr-4"
        >
          {{ type.text }}
        </vs-radio>
      </vs-col>
    </div>
    <vs-divider />
    <div class="vx-row">
      <vs-col :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-input
          class="inputx w-full"
          :label="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .elementName.label[languageSelected]
          "
          :placeholder="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .elementName.placeholder[languageSelected]
          "
          v-model="elementNameR"
        />
      </vs-col>
      <vs-col :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-input
          class="inputx w-full"
          :label="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .nameSpace.label[languageSelected]
          "
          :placeholder="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .nameSpace.placeholder[languageSelected]
          "
          v-model="nameSpaceR"
        />
      </vs-col>
      <vs-col :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-input
          class="inputx w-full mt-1"
          :label="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .languageCode.placeholder[languageSelected]
          "
          :placeholder="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .languageCode.placeholder[languageSelected]
          "
          v-model="languageCodeR"
        />
      </vs-col>
    </div>
    <vs-divider class="mt-base" />
    <div class="vx-row mt-2">
      <vs-col :vs-lg="'6'" :vs-xs="'6'" :vs-sm="'6'" class="mt-0 mb-4">
        <span class="custom-label">
          {{
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .requireVariables[languageSelected]
          }}
        </span>
        <vx-slider v-model="useVariablesR" />
      </vs-col>
      <vs-col
        :vs-lg="'6'"
        :vs-xs="'6'"
        :vs-sm="'6'"
        class="mt-0 mb-4"
        v-if="useVariablesR && allowFillOneVariable"
      >
        <vx-tooltip
          class="buttons-preview-tooltip float-right"
          :text="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .fillOneVariableTooltip[languageSelected]
          "
          position="left"
        >
          <feather-icon icon="InfoIcon" svgClasses="h-5 w-5" />
        </vx-tooltip>
        <span class="custom-label">
          {{
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .fillOneVariable[languageSelected]
          }}
        </span>
        <vx-slider v-model="fillOneVariableR" />
      </vs-col>

      <vs-list v-if="useVariablesR && !fillOneVariableR">
        <vs-list-header
          icon-pack="feather"
          icon="icon-code"
          :title="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .variables[languageSelected]
          "
        ></vs-list-header>
        <draggable
          v-model="variablesR"
          class=".variables"
          style="overflow-y: auto; max-height: 250px"
        >
          <vs-list-item
            v-for="(text, index) in variablesR"
            :key="index"
            icon-pack="feather"
            icon="icon-chevron-right"
            rounded
            :subtitle="text"
            style="cursor: move"
          >
            <vs-button
              radius
              type="flat"
              icon="clear"
              @click="removeVariable(index)"
            ></vs-button>
          </vs-list-item>
        </draggable>
      </vs-list>
    </div>
    <div class="vx-row mt-2 mb-base" v-if="useVariablesR && !fillOneVariableR">
      <vs-col :vs-lg="'12'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-input
          :placeholder="
            `${
              lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
                .variableInputPlaceholder[languageSelected]
            } ${variablesR.length + 1}`
          "
          class="inputx w-full mb-0"
          v-model="newVariable"
          @keypress.enter="addVariable"
        />
      </vs-col>
    </div>
  </vx-card>
</template>

<script>
import { mapState } from 'vuex'
import draggable from 'vuedraggable'

export default {
  name: 'WhatsAppOutgoing',
  props: {
    survey: Object,
    elementName: {
      type: String
      // default: 'chatclub_southcone_welcome_v1' // 'chatclub_open_session_v2'
    },
    languageCode: {
      type: String,
      default: 'es'
    },
    namespace: {
      type: String
    },
    variables: {
      type: Array
    },
    useVariables: {
      type: Boolean,
      default: false
    },
    fillOneVariable: {
      type: Boolean,
      default: false
    },
    messageType: {
      type: String,
      default: 'HSM'
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      allowFillOneVariable: false, // feature unavailable

      languageCodeR: '',
      newVariable: null,
      elementNameR: '',
      variablesR: [],
      nameSpaceR: '',
      useVariablesR: false,
      fillOneVariableR: false,
      messageTypeR: '',
      messageTypes: [{ text: 'HSM' }, { text: 'Template' }]
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    removeVariable(index) {
      this.variablesR.splice(index, 1)
    },
    addVariable() {
      if (this.newVariable && this.newVariable.length > 0) {
        this.variablesR.push(`${this.newVariable}`)
        this.newVariable = ''
        var container = this.$el.querySelector('.variables')
        if (container) {
          container.scrollTop = container.scrollHeight - 100
        }
      }
    }
  },
  watch: {
    variablesR(newVal) {
      this.$emit('update:variables', newVal)
    },
    languageCodeR(newVal) {
      this.$emit('update:languageCode', newVal)
    },
    elementNameR(newVal) {
      this.$emit('update:elementName', newVal)
    },
    nameSpaceR(newVal) {
      this.$emit('update:namespace', newVal)
    },
    useVariablesR(newVal) {
      this.$emit('update:useVariables', newVal)
    },
    fillOneVariableR(newVal) {
      this.$emit('update:fillOneVariable', newVal)
    }
  },
  mounted() {
    this.nameSpaceR = `${this.namespace || ''}`
    this.elementNameR = `${this.elementName}`
    this.languageCodeR = `${this.languageCode}`

    const index = this.messageTypes.findIndex(
      item => item.text === this.messageType
    )
    this.messageTypeR = this.messageTypes[index > -1 ? index : 0].text

    this.variablesR = this.variables || []
    this.useVariablesR = this.useVariables
    this.fillOneVariableR = this.fillOneVariable
  }
}
</script>
