var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',{staticClass:"overflow-hidden mb-2",attrs:{"title":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.title[
      _vm.languageSelected
    ],"subtitle":_vm.lang.helpTooltips.surveys.hsm[_vm.languageSelected]}},[_c('div',{staticClass:"vx-row"},[_c('vs-col',{attrs:{"vs-lg":'6',"vs-xs":'12',"vs-sm":'12'}},_vm._l((_vm.messageTypes),function(type){return _c('vs-radio',{key:type.text,staticClass:"mr-4",attrs:{"vs-name":type.text,"vs-value":type.text},on:{"input":function($event){_vm.$emit('update:messageType', $event)}},model:{value:(_vm.messageTypeR),callback:function ($$v) {_vm.messageTypeR=$$v},expression:"messageTypeR"}},[_vm._v("\n        "+_vm._s(type.text)+"\n      ")])}),1)],1),_vm._v(" "),_c('vs-divider'),_vm._v(" "),_c('div',{staticClass:"vx-row"},[_c('vs-col',{attrs:{"vs-lg":'6',"vs-xs":'12',"vs-sm":'12'}},[_c('vs-input',{staticClass:"inputx w-full",attrs:{"label":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .elementName.label[_vm.languageSelected],"placeholder":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .elementName.placeholder[_vm.languageSelected]},model:{value:(_vm.elementNameR),callback:function ($$v) {_vm.elementNameR=$$v},expression:"elementNameR"}})],1),_vm._v(" "),_c('vs-col',{attrs:{"vs-lg":'6',"vs-xs":'12',"vs-sm":'12'}},[_c('vs-input',{staticClass:"inputx w-full",attrs:{"label":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .nameSpace.label[_vm.languageSelected],"placeholder":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .nameSpace.placeholder[_vm.languageSelected]},model:{value:(_vm.nameSpaceR),callback:function ($$v) {_vm.nameSpaceR=$$v},expression:"nameSpaceR"}})],1),_vm._v(" "),_c('vs-col',{attrs:{"vs-lg":'6',"vs-xs":'12',"vs-sm":'12'}},[_c('vs-input',{staticClass:"inputx w-full mt-1",attrs:{"label":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .languageCode.placeholder[_vm.languageSelected],"placeholder":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .languageCode.placeholder[_vm.languageSelected]},model:{value:(_vm.languageCodeR),callback:function ($$v) {_vm.languageCodeR=$$v},expression:"languageCodeR"}})],1)],1),_vm._v(" "),_c('vs-divider',{staticClass:"mt-base"}),_vm._v(" "),_c('div',{staticClass:"vx-row mt-2"},[_c('vs-col',{staticClass:"mt-0 mb-4",attrs:{"vs-lg":'6',"vs-xs":'6',"vs-sm":'6'}},[_c('span',{staticClass:"custom-label"},[_vm._v("\n        "+_vm._s(_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .requireVariables[_vm.languageSelected])+"\n      ")]),_vm._v(" "),_c('vx-slider',{model:{value:(_vm.useVariablesR),callback:function ($$v) {_vm.useVariablesR=$$v},expression:"useVariablesR"}})],1),_vm._v(" "),(_vm.useVariablesR && _vm.allowFillOneVariable)?_c('vs-col',{staticClass:"mt-0 mb-4",attrs:{"vs-lg":'6',"vs-xs":'6',"vs-sm":'6'}},[_c('vx-tooltip',{staticClass:"buttons-preview-tooltip float-right",attrs:{"text":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .fillOneVariableTooltip[_vm.languageSelected],"position":"left"}},[_c('feather-icon',{attrs:{"icon":"InfoIcon","svgClasses":"h-5 w-5"}})],1),_vm._v(" "),_c('span',{staticClass:"custom-label"},[_vm._v("\n        "+_vm._s(_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .fillOneVariable[_vm.languageSelected])+"\n      ")]),_vm._v(" "),_c('vx-slider',{model:{value:(_vm.fillOneVariableR),callback:function ($$v) {_vm.fillOneVariableR=$$v},expression:"fillOneVariableR"}})],1):_vm._e(),_vm._v(" "),(_vm.useVariablesR && !_vm.fillOneVariableR)?_c('vs-list',[_c('vs-list-header',{attrs:{"icon-pack":"feather","icon":"icon-code","title":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .variables[_vm.languageSelected]}}),_vm._v(" "),_c('draggable',{staticClass:".variables",staticStyle:{"overflow-y":"auto","max-height":"250px"},model:{value:(_vm.variablesR),callback:function ($$v) {_vm.variablesR=$$v},expression:"variablesR"}},_vm._l((_vm.variablesR),function(text,index){return _c('vs-list-item',{key:index,staticStyle:{"cursor":"move"},attrs:{"icon-pack":"feather","icon":"icon-chevron-right","rounded":"","subtitle":text}},[_c('vs-button',{attrs:{"radius":"","type":"flat","icon":"clear"},on:{"click":function($event){_vm.removeVariable(index)}}})],1)}),1)],1):_vm._e()],1),_vm._v(" "),(_vm.useVariablesR && !_vm.fillOneVariableR)?_c('div',{staticClass:"vx-row mt-2 mb-base"},[_c('vs-col',{attrs:{"vs-lg":'12',"vs-xs":'12',"vs-sm":'12'}},[_c('vs-input',{staticClass:"inputx w-full mb-0",attrs:{"placeholder":((_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .variableInputPlaceholder[_vm.languageSelected]) + " " + (_vm.variablesR.length + 1))},on:{"keypress":function($event){if(!('button' in $event)&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addVariable($event)}},model:{value:(_vm.newVariable),callback:function ($$v) {_vm.newVariable=$$v},expression:"newVariable"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }